<template>
  <template v-if="hasPermission">
    <Field
      :label="label"
      :required="required"
      :class="classNotResults"
      :error-message="errorMessage"
      :variant="errorMessage ? 'danger' : variant"
      :message="errorMessage ?? message"
    >
      <o-autocomplete
        v-bind="autocompleteProps"
        ref="autocomplete"
        v-model="text"
        :required="required"
        :class="isLoading ? 'is-loading' : ''"
        :readonly="readonly"
        :clearable="!isLoading && clearable"
        :data="filteredData"
        :keep-first="keepFirst || isOnlyOneResult || isRequieredAndHasText"
        :open-on-focus="true"
        :select-on-click-outside="selectOnClickOutside"
        :disabled="disabled || (!userHasInteracted && (loading || oLoading))"
        :icon-right="isLoading ? 'loading' : iconRight"
        :value="iValue"
        @typing="onInput"
        @blur="onBlur"
        @focus="onFocus"
        @select="onSelect"
      >
        <template #empty>
          <o-loading
            v-if="isLoading"
            :active="isLoading"
            :full-page="false"
            icon-size="small"
            class="loading-not-hoverable"
          />
          <p v-else-if="filteredData" class="not-results-not-hoverable">
            Sin resultados encontrados
          </p>
        </template>
        <template v-if="$slots.footer" #footer>
          <hr class="m-0 mb-2" />
          <slot name="footer" />
        </template>
      </o-autocomplete>
    </Field>
  </template>
</template>
<script>
import { Field } from '@/components';
import { sortBy, prop, concat } from '@/utils/Ramda';
import { PermissionValidator } from '@/utils/Secure';
import { usePickProps } from './conf/composables';
import { useComponentUtils } from './conf/composables';
import { computed, toRefs, ref, getCurrentInstance, watch, onMounted, onBeforeMount } from 'vue';
export default {
  components: {
    Field,
  },
  props: {
    api: { type: Object, default: () => ({ full: false, query: null, params: [] }) },
    awaitFirstValue: { type: Boolean, default: false },
    clearOnSelect: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    dataPreProcessor: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
    field: { type: String, default: 'name' },
    filteredByModel: { type: Array, default: () => [] },
    ignoreElement: { type: Boolean || String, default: false },
    loading: { type: Boolean, default: false },
    localFiltering: { type: Boolean, default: true },
    minToSearch: { type: Number, default: 2 },
    required: { type: Boolean, default: false },
    model: { type: String, default: null },
    permission: { type: Object, default: null },
    sortData: { type: Boolean, default: true },
    selectOnBlur: { type: Boolean, default: true },
    storeResults: { type: Boolean, default: false },
    setFirst: { type: Boolean, default: false },
    value: { type: [Object, String], default: null },
    keepFirst: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    iconRight: { type: String, default: 'text-search' },
    selectOnClickOutside: { type: Boolean, default: true },
    setFirstItem: { type: Boolean, default: true },
    label: { type: String, default: '' },
    errorMessage: { type: String, default: undefined },
    variant: { type: String, default: '' },
    message: { type: String, default: '' },
  },
  emits: [
    'defaultData',
    'allData',
    'primitive-input',
    'select',
    'clear',
    'input',
    'select-raw',
    'update:loading',
    'isLoading',
    'error:not-selected',
    'error:not-found',
  ],
  setup(props, { attrs, emit }) {
    const { propsPicked: autocompleteProps } = usePickProps('AutocompleteProps', { props, attrs });

    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const autocomplete = ref(null);
    const pData = toRefs(props).data;
    const oApi = toRefs(props).api;
    const pValue = toRefs(props).value;
    const oLoading = ref(false);
    const cancelToken = ref(null);
    const fetchingData = ref(false);
    const timeout = ref({ getData: 0, selector: 0 });
    const text = ref('');
    const selected = ref(null);
    const { Normalize } = useComponentUtils();
    const isFilterless = ref(false);
    const userHasInteracted = ref(false);
    const hasBlurPromise = ref(false);
    const isDropDownHover = ref(false);
    const isFirstValue = ref(true);
    const isFetchCompleted = ref(false);

    const params = computed(() => {
      const inputText = Normalize(text.value, {
        encodeUri: true,
        replace: { find: ['\n'], value: ['\\n'] },
      });
      let paramsStr = '';
      const params = [];
      if (oApi.value.query && inputText) params.push(`${oApi.value.query}=${inputText}`);
      if (oApi.value.params && oApi.value.params.length)
        oApi.value.params.map((p) =>
          p.id && p.value
            ? params.push(`${p.id}=${p?.toJSONparam ? JSON.stringify(p.value) : p.value}`)
            : null,
        );
      if (oApi.value.full) params.push(`per_page=99999`);
      paramsStr = params.join('&');
      return paramsStr ? `?${paramsStr}` : '';
    });

    const hasPermission = computed(() => {
      if (!props.permission || props.permission === true) return true;
      return PermissionValidator(props.permission);
    });

    const cancelRequest = () => {
      clearTimeout(timeout.value.getData);
      if (cancelToken.value) cancelToken.value.cancel('Avoid multiple');
      oLoading.value = false;
      fetchingData.value = false;
    };
    const iValue = computed(() => {
      const { field, value, model } = props;
      let result = null;
      if (model && value) {
        const rDat = oData.value.fields.filter((d) => d[model] == value)[0];
        result = (rDat && rDat[field]) || '';
      }
      if (result) return result;
      else return value;
    });
    const isOnlyOneResult = computed(() => {
      return filteredData.value.length === 1;
    });
    const isRequieredAndHasText = computed(() => {
      return props.required && text.value?.length > 0;
    });
    const awaitingFirstValue = computed(() => {
      return props.awaitFirstValue && isFirstValue.value;
    });
    const isLoading = computed(() => {
      return props.loading || oLoading.value || awaitingFirstValue.value;
    });
    const classNotResults = computed(() => {
      return { 'is-result-not-found': isResultNotFound.value };
    });
    const isResultNotFound = computed(() => {
      return filteredData.value.length === 0;
    });
    const handlesEmit = (data) => {
      if (data === 'clear') {
        text.value = '';
        emit('clear', true);
      } else {
        emit('select', data);
        emit('input', data);
        emit('select-raw', selected.value);
      }
    };
    const makeRequest = async () => {
      try {
        isFetchCompleted.value = false;
        oLoading.value = true;
        cancelToken.value = Api.cancelToken;
        const { data } = await Api.get(`${oApi.value.url}${params.value}`, {
          cancelToken: cancelToken.value.token,
        });
        oLoading.value = false;
        fetchingData.value = false;
        isFetchCompleted.value = true;
        return data;
      } catch (err) {
        console.error(err);
        oLoading.value = false;
        fetchingData.value = false;
        return [];
      }
    };
    const normalizeText = (text = '') => {
      return Normalize(text, { lower: true, replace: { find: [','], value: [''] } });
    };
    const generateQueryKey = (fields = []) => {
      return fields.map((field) => {
        if (typeof field === 'object') field.queryKey = normalizeText(field[props.field] || 'name');
      });
    };
    const oData = ref({ fields: props.data, query: '' });
    const setDefaultData = (data) => {
      emit('defaultData', data[0]);
    };
    const setData = (fields) => {
      const { dataPreProcessor, ignoreElement, model } = props;
      let preFields = fields;
      if (dataPreProcessor) preFields = dataPreProcessor(fields);
      if (ignoreElement && model)
        preFields = fields.filter((field) => field[model] !== ignoreElement);
      generateQueryKey(preFields);
      oData.value = { fields: preFields, query: normalizeText(text.value) };
      emit('allData', preFields);
    };
    const allowOnInputRequest = () => {
      const { api, minToSearch } = props;
      const _text = oData.value.query;
      const selectedStr = selected.value && selected?.[props.field];
      const isCurrent = _text == selectedStr;
      const hasApi = !!api?.url;
      const isMinToSearch = _text?.length >= minToSearch;
      const isPartial = !api.full;
      return hasApi && !isCurrent && isMinToSearch && isPartial;
    };
    const reset = () => {
      selected.value = null;
    };
    const getData = async () => {
      if (allowOnInputRequest()) {
        cancelRequest();
        fetchingData.value = true;
        timeout.value.getData = setTimeout(async () => {
          const data = await makeRequest();
          setDefaultData(data);
          setData(data);
          const hasData = !data.length && isFetchCompleted.value;
          emit('error:not-found', hasData);
        }, 500);
      }
    };
    const onInput = (query) => {
      emit('error:not-selected', !query);
      if (query?.length === 1) emit('error:not-found', true);
      oData.value.query = Normalize(query);
      isFilterless.value = false;
      getData();
      emit('primitive-input', query);
    };
    const onBlur = () => {
      if (!props.selectOnBlur) return;

      clearTimeout(timeout.value.selector);
      setTimeout(() => {
        if (!selected.value && !autocomplete.value?.isActive) emit('error:not-selected', true);

        if (fetchingData.value) hasBlurPromise.value = true;
        else if (!isDropDownHover.value && !selected.value) {
          reset();
        }
      }, 100);
    };
    const onFocus = () => {
      userHasInteracted.value = true;
      timeout.value.selector = setTimeout(() => {
        isFilterless.value = true;
        if (autocomplete.value) {
          autocomplete.value.$refs?.input?.$refs?.input?.select();
        }
      }, 50);
    };
    const onSelect = (cSelected) => {
      const { clearOnSelect, clearable, model } = props;
      if (cSelected) emit('error:not-found', false), emit('error:not-selected', false);
      isFirstValue.value = false;
      const item = model && cSelected ? cSelected[model] : cSelected;
      if (selected.value == cSelected && clearable) {
        handlesEmit('clear');
        if (selected.value == cSelected) return;
      }
      selected.value = cSelected;
      if (clearable) handlesEmit(item ? item : 'clear');
      else handlesEmit(item);
      isDropDownHover.value = false;
      const tempText = text.value;
      if (clearOnSelect) reset();
      else if (tempText && !item && !clearable)
        setTimeout(() => {
          text.value = tempText;
        }, 10);
    };
    const setSelected = () => {
      if (filteredData.value.length == 1) {
        selected.value = filteredData.value[0];
        if (autocomplete.value) autocomplete.value?.setSelected(filteredData.value[0] || '');
      }
    };
    const setFirstItem = () => {
      const { field } = props;
      const item = filteredData.value[0];
      if (item) {
        text.value = item[field];
        onSelect(item);
      } else {
        reset();
      }
    };
    const setInitialText = () => {
      if (iValue.value) {
        hasBlurPromise.value = true;
        text.value = String(iValue.value);
        setSelected();
      } else if (isOnlyOneResult.value || props.setFirst) {
        if (props.setFirstItem) {
          setFirstItem();
          setSelected();
        }
      }
    };
    const getFullData = async () => {
      if (oApi.value.full) {
        const { storeResults } = props;
        let data = storeResults ? JSON.parse(sessionStorage.getItem(oApi.value.url)) : null;
        if (!data) {
          const result = await makeRequest();
          data = result;
        }
        if (storeResults) sessionStorage.setItem(oApi.value.url, JSON.stringify(data));
        setData(data);
      }
    };
    const updateValue = (value) => {
      const { field } = props;
      if (typeof value === 'object' && value) value = value[field];
      else if (typeof value === 'string') text.value = value;
      else if (typeof value === 'number') text.value = iValue.value;
      else text.value = '';
      setSelected();
      if (isFirstValue.value) isFirstValue.value = false;
    };
    const sortedDataByQuery = computed(() => {
      const { field, sortData } = props;
      if (!field || !sortData) return oData.value.fields;
      const sortByField = sortBy(prop(field));
      const queryAtStart = [];
      const queryOtherPosition = [];
      const query = oData.value.query;
      oData.value.fields.map((oField) => {
        const oFieldQuery = oField?.queryKey || normalizeText(oField[field]);
        if (oFieldQuery.indexOf(query) === 0) queryAtStart.push(oField);
        else queryOtherPosition.push(oField);
      });
      return concat(sortByField(queryAtStart), sortByField(queryOtherPosition));
    });
    const filterDataByModel = computed(() => {
      const { model, filteredByModel } = props;
      const fields = sortedDataByQuery.value;
      if (model && filteredByModel.length)
        return fields.filter((field) => filteredByModel.indexOf(field[model] < 0));
      else return fields;
    });
    const filteredData = computed(() => {
      const { field, model, localFiltering } = props;
      let fields = filterDataByModel.value;
      if (!fields.length) return [];
      const query = normalizeText(text.value) || '';
      if (isFilterless.value) {
        let data = fields;
        if (selected.value) {
          const compareObjects = !!(typeof selected.value === 'object' && model);
          data = [selected.value];
          const query = normalizeText(selected.value[field] || selected);
          const tempData = fields.filter((opt) => {
            const isSameObject = compareObjects && selected.value[model] === opt[model];
            const queryKey = opt?.queryKey || normalizeText(opt[field] || opt);
            return compareObjects ? !isSameObject : true && queryKey != query;
          });
          data = data.concat(tempData);
        }
        return data;
      } else {
        const useExactMatch = query && !userHasInteracted.value;
        if (!localFiltering) return fields;
        return fields.filter((opt) => {
          if (model && opt[model] === query) return true;
          const queryKey = opt?.queryKey || normalizeText(opt[field] || opt);
          if (typeof queryKey === 'object') return true;
          if (useExactMatch) return queryKey === query;
          else return queryKey.indexOf(query) >= 0;
        });
      }
    });
    onBeforeMount(() => {
      if (iValue.value) isFirstValue.value = false;
    });
    onMounted(async () => {
      await getFullData();
      if ((props.awaitFirstValue && !isFirstValue.value) || !props.awaitFirstValue)
        setInitialText(true);
      if (autocomplete.value) {
        autocomplete.value.$refs.dropdown.onmouseenter = () => {
          isDropDownHover.value = true;
        };
        autocomplete.value.$refs.dropdown.onmouseleave = () => {
          isDropDownHover.value = false;
        };
      }
      watch(pData, (data) => {
        if (props.dataPreProcessor) setData(props.dataPreProcessor(data));
        else setData(data);
        setInitialText();
        if (props.setFirstItem) setFirstItem();
      });
      watch(
        () => oApi.params,
        async (data) => {
          if (JSON.stringify(data) == JSON.stringify(oApi.value.params[0])) return;
          oApi.value.params = data;
          if (oApi.value.full) {
            reset();
            await getFullData();
            setInitialText();
          }
        },
      );
      watch(pValue, (value) => {
        const { model } = props;
        if (selected.value) {
          const selectedValue = model ? selected.value[model] : selected;
          if (value === selectedValue) return;
        }
        if (text.value === value) return;

        if (value === -1) {
          setData([]);
          value = '';
        }
        updateValue(value);
      });
      watch(oLoading, (value) => {
        emit('update:loading', value);
        emit('isLoading', value);
      });
    });
    return {
      text,
      autocompleteProps,
      hasPermission,
      params,
      onInput,
      filteredData,
      oApi,
      oLoading,
      cancelToken,
      fetchingData,
      timeout,
      selected,
      isFilterless,
      userHasInteracted,
      sortedDataByQuery,
      filterDataByModel,
      oData,
      onBlur,
      onFocus,
      onSelect,
      autocomplete,
      iValue,
      isOnlyOneResult,
      isLoading,
      isRequieredAndHasText,
      isResultNotFound,
      classNotResults,
    };
  },
};
</script>
<style lang="sass" scoped>
.is-result-not-found .control .autocomplete :deep(.dropdown-menu)
  .dropdown-item
    &:hover
      background-color: #FFF !important
      cursor: default !important
      color: $grey-dark !important
:deep(.autocomplete)
  hr
    background-color: $black
    height: 1px
  .is-loading ~ .icon
    animation-name: icon-spin
    animation-iteration-count: infinite
    animation-timing-function: linear
    animation-duration: 2s
  .dropdown-menu
    .dropdown-item
      min-height: 36px
      &:hover
        background-color: #B5E4DE !important
      &:hover
          .loading-not-hoverable, .not-results-not-hoverable
            background-color: #FFF !important
            cursor: default
      .not-results-not-hoverable
        height: 36px
</style>
